
html {
    min-height: 100%;
    background: #f0ebe7;
    background-size: cover;
    }

    body, div, form, input, select, p { 
    padding: 0;
    margin: 0;
    outline: none;
    font-family: Roboto, Arial, sans-serif;
    font-size: 16px;
    color: #000;
    }
   
    h1, h2 {
    text-transform: uppercase;
    font-weight: 400;
    }
    h2 {
    margin: 0 0 0 8px;
    }
    .main-block {
    align-items: center;
    height: 100%;
    padding: 25px;
    margin: auto;
    max-width: 600px;

    }
    form {
    padding: 25px;
    }

    .fa-graduation-cap {
    font-size: 72px;
    }
    form {
    background: rgba(255, 255, 255,1); 
    }
    .title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    }

    .info {
    display: flex;
    flex-direction: column;
    }

    .gathered {
    display: none;
    }

    .uploadBtn {
        cursor: pointer;
        display: block;
        max-width: 120px;
        border-radius: 13px;
    }

    .uploadBtn:hover {
        background-color: #524d4d;
    }
    
    input, select {
    padding: 5px;
    margin: 10px;
    background: white;
    
    border: 1px solid black;
    }
    input::placeholder {
    color: #524d4d;
    }
    option:focus {
    border: none;
    }
    option {
    background: white; 
    border: none;
    }
    .checkbox input {
    margin: 0 10px 0 0;
    vertical-align: middle;
    }
    .checkbox a {
    color: #26a9e0;
    }
    .checkbox a:hover {
    color: #85d6de;
    }
    .btn-item, button {
    padding: 10px 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    border-radius: 5px; 
    border: none;
    background: #26a9e0; 
    text-decoration: none;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    }
    
    .btn-item {
    display: inline-block;
    margin: 20px 5px 0;
    }
    
    button {
    width: 150px;
    }
    
    button:hover, .btn-item:hover {
    background: #85d6de;
    }

    .change {
        background: #f14040;  
    }
    .change:hover {
        background: #f18080;  
    }
    
    button:disabled,
    button[disabled]{
        background: #85d6de;
    }

    .error {
        color:#ff0000
    }
