

    .main-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 25px;

    }
    .left-part {
    padding: 25px;
    border: thin solid #000;
    border-radius: 2%;
    margin-bottom: 10px;
    }
    .left-part {
    text-align: center;
    }


    
    @media (min-width: 750px) {

    .main-form {
    flex-direction: row;
    height: calc(100% - 50px);
    }
    .left-part, form {
    flex: 1;
    height: 100%;
    margin-right: 15px;
    }
    }